<template>
  <div class="complete-service" @click="$emit('click')">
    <div class="complete-service-left">
      <van-icon name="warning" class="icon-warning" />
      <span>该网页需获取个人信息才能使用完整服务， 当前仅可使用部分内容。</span>
    </div>
    <div class="complete-service-right">
      <span>使用完整服务</span>
      <van-icon name="arrow" />
    </div>
  </div>
</template>

<script>
import { Icon } from "vant";
import Vue from "vue";
Vue.use(Icon);
export default Vue.extend({});
</script>

<style lang="less" scoped>
.complete-service {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 18px 15px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
  display: flex;
  align-items: center;
  &-left {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    align-items: center;
    .icon-warning {
      color: #ff5757;
      font-size: 15px;
      background-color: #ffffff;
      border-radius: 50%;
      border: 0px;
      margin-right: 8px;
    }
  }
  &-right {
    margin-left: 10px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    flex-shrink: 0;
  }
}
</style>
