<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-09-04 16:41:42
 * @LastEditors: liang
 * @LastEditTime: 2024-09-04 16:51:47
-->
<template>
  <van-overlay :show="show" :z-index="2016">
    <div class="wrapper">
      <van-loading size="24px" vertical color="#fff">{{ text }}</van-loading>
    </div>
  </van-overlay>
</template>
<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    text: {
      type: String,
      default: "登录中...",
    },
  },
  data() {
    return {
      show: true,
    };
  },
});
</script>
<style lang="less" scoped>
.wrapper {
  position: absolute;
  top: 48%;
  width: 100%;
}
</style>
