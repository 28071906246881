import http from '@/utils/http';

export function checkUserOpenId(token: string) {
  return http.get(`/portal-resources/1/PortalWxInfo/checkOpenId`, {

  }, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token
    // }
  });
}

export function bindOpenId(wxCode: string, token: string) {
  return http.get(`/portal-resources/1/PortalWxInfo/bindOpenId/${wxCode}`, {}, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token
    // }
  });
}

export function userAmountStatistics(token: string) {
  return http.get(`/portal-resources/1/portalAccount/accountDetailCount`, {}, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token,
    // }
  });
}

export function getUserWithdrawRecord(token: string) {
  return http.get(`/portal-resources/1/portalAccountDetail/queryExpenditureList`, {}, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token,
    // }
  });
}

export function userWithdraw(amount: number, token: string) {
  return http.post(`/portal-resources/1/portalAccount/withdrawal?amount=${amount}`, {}, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token,
    // }
  });
}