<!--
 * @Descripttion: 
 * @Author: liang
 * @Date: 2024-08-27 10:40:30
 * @LastEditors: liang
 * @LastEditTime: 2024-09-13 16:46:37
-->
<template>
  <div class="rule-box">
    <div class="title"></div>
    <div class="rule-content">
      <div class="rule-div">
        <b>1、活动奖励机制</b>
        <div>
          <b>分享活动链接奖励：</b
          >通过平台生成专属活动链接，分享到朋友圈或好友，即可获得50元优惠券，每位用户限领一次。
        </div>
        <div>
          <b>邀请新用户注册奖励：</b
          >通过专属链接成功邀请新用户注册，每成功邀请10人注册成功，即可获50元优惠券，以此类推，最高可获取200元。
        </div>
        <div>
          <b>平台使用反馈奖励：</b
          >提交有效的平台使用反馈并通过审核后，每反馈有效问题数量达5条时，即可获取50元现金红包或优惠券；以此类推，最高可获取100元现金和100元优惠券。
        </div>
      </div>
      <div class="rule-div">
        <b>2、奖励发放方式</b>
        <div>微信红包：直接提现，极速到账。</div>
        <div>
          代金券/优惠券：发放到你的账户，可在【我的奖励-优惠券/代金券】中查看使用。
        </div>
      </div>
      <div class="rule-div">
        <b>3、其他活动规则与说明</b>
        <div>活动仅限已注册成为平台用户的账号参与；</div>
        <div>
          对于恶意注册、作弊行为谋取不正当利益的用户，平台有权取消其活动资格，并收回所有已发放奖励。
        </div>
        <div>
          您参与活动的个人信息仅用于本次活动的统计和奖励发放，绝不会泄露给第三方或用于其他商业用途。
        </div>
        <div>
          本次活动的最终解释权归平台所有。如有任何问题或疑问，请联系我们客服：<b
            ><a style="color: #333" href="tel:400-005-8008">400-005-8008</a></b
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
export default Vue.extend({});
</script>
<style lang="less" scoped>
.rule-box {
  font-size: 14px;
  height: 500px;
  .title {
    background: url('../../../assets/img/invite/rule_title.png') center center
      no-repeat;
    background-size: auto 20px;
    width: 130px;
    height: 50px;
    margin: 0 auto;
  }
  .rule-content {
    font-weight: 400;
    font-size: 14px;
    color: #391916;
    padding: 0 30px;
    line-height: 24px;
    .rule-div {
      margin-bottom: 20px;
    }
  }
}
</style>
