/*
 * @Author: zhangguoliang
 * @Date: 2021-09-26 10:38:15
 * @LastEditors: renmingming
 * @LastEditTime: 2022-10-27 14:01:33
 */

import { bindOpenId, checkUserOpenId } from '@/api/withdraw';
import { Raw } from '@/interface';
import { message } from 'ant-design-vue';
import store from '@/store';

/**
 * @description: 从目标对象中取出模板对象所需的属性
 * @param {Raw} target 目标对象
 * @param {Raw} template 模板
 * @return {Raw}
 */
export function cloneWithTemplate(target: Raw, template?: Raw): Raw {
  if (!template) return target;
  return Object.keys(template).reduce<Raw>((prev, curr) => {
    prev[curr] = target[curr] || template[curr];
    return prev;
  }, {});
}

/**
 * @description: 校验文件格式
 * @param {*} fileName 文件名称
 * @param {*} type 文件类型
 * @return {*}
 */
export const checkFileType = (fileName, ...arg) => {
  const fileArr = arg.length ? arg.map((i) => i.toLowerCase()) : ['zip'];
  if (!fileName) return false;
  const fileType = fileName.substring(fileName.lastIndexOf('.') + 1);
  return fileArr.includes(fileType.toLowerCase());
};
/**
 * @description: 下载文件流
 * @param {*} data 文件流
 * @param {*} fileName 文件名
 * @param {*} fileType 文件类型
 * @return {*}
 */
export function downloadFile(
  data: ArrayBuffer,
  fileName = new Date().toString(),
  fileType = 'excel'
): void {
  console.log('🚀 获取之后a', data);
  let type = '';
  switch (fileType) {
    case 'excel':
      type =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'pdf':
      type = 'application/pdf';
      break;
    default:
      type = 'application/zip';
      break;
  }

  const resBlob = new Blob([data]);
  const reader = new FileReader();
  reader.readAsText(resBlob, 'utf-8');
  reader.onload = () => {
    try {
      const result = JSON.parse(reader.result as string);
      message.error(result.message);
      return '';
    } catch (error) {
      const blob = new Blob([data], { type });
      if ('download' in document.createElement('a')) {
        // 非IE下载
        const elink = document.createElement('a') as HTMLAnchorElement;
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = window.URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        window.URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fileName);
        }
      }
    }
  };
}

/**
 * 两数相加之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function accAdd(arg1: number, arg2: number): number {
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

/**
 * 两数相减之和,解决精度丢失问题
 * @param arg1
 * @param arg2
 * @returns number
 */
export function subtract(arg1: number, arg2: number): string {
  if (isNaN(arg1) || isNaN(arg2)) return '';
  let r1, r2;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  const m = Math.pow(10, Math.max(r1, r2));
  const n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 *
 * 浏览器的localStorage
 *
 */
export function dbGet(name: string): unknown {
  let value = localStorage.getItem(name) as string;
  if (/^\{.*\}$/.test(value)) {
    value = JSON.parse(value);
  }
  return value;
}
export function dbSet(name: string, value): void {
  if (typeof value === typeof {}) {
    value = JSON.stringify(value);
  }
  return localStorage.setItem(name, value);
}
export function dbRemove(name: string): void {
  return localStorage.removeItem(name);
}

export function getURLParamsByKey(key) {
  const searchURL = location.search;
  const params = new URLSearchParams(searchURL);
  return params.get(key)
}

export function bindUserOpenId(currentEnv: string, currentUrl: string, wxCode: string, token: string, router: any) {
  if (/MicroMessenger/i.test(currentEnv)) {
    checkUserOpenId(token).then((res) => {
      if (!res) {
        //未绑定openId
        if (wxCode !== "") {
          console.log(wxCode);
          bindOpenId(wxCode, token).then((res) => {
            console.log(res);
          });
          localStorage.removeItem("wxCode");
          store.commit("SET_WXCODE", '');
          // history.back();
        } else {
          getWechatCode(currentEnv, currentUrl, router);
        }
      } else {
        //已经绑定openId无需进行任何处理
      }
    });
  }
}

export function getWechatCode(currentEnv: string, currentUrl: string, router: any) {
  if (/MicroMessenger/i.test(currentEnv)) {
    const scope = "snsapi_base"; //弹出授权页面
    const baseUrlEncode = encodeURIComponent(currentUrl);
    const appid = "wx17081ce61493aba9";
    const urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${baseUrlEncode}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`;
    console.log("urls", urls);
    location.replace(urls);
    // location.replace = urls;
    // window.history.replaceState(null, null, urls)
    // router.replace({
    //   path: urls,
    //   replace: true
    // })
  }

}
