var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-form"},[_c('div',{staticClass:"login-form-username"},[_c('van-field',{staticClass:"login-form-username-field",attrs:{"placeholder":"联系人"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"login-form-mobile"},[_c('van-field',{staticClass:"login-form-mobile-field",attrs:{"type":"tel","maxlength":"11","placeholder":"手机号"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})],1),_c('div',{staticClass:"login-form-code"},[_c('div',{staticClass:"login-form-code-input"},[_c('van-field',{staticClass:"login-form-code-input-field",attrs:{"maxlength":"6","type":"tel","placeholder":"短信验证码"},model:{value:(_vm.smscode),callback:function ($$v) {_vm.smscode=$$v},expression:"smscode"}})],1),_c('div',{staticClass:"login-form-code-action"},[_c('van-button',{staticClass:"login-form-code-action-btn",attrs:{"type":"primary","disabled":_vm.codeDisabled},on:{"click":_vm.onClickSendSms}},[_vm._v(" "+_vm._s(_vm.codeText)+" ")])],1)]),_c('div',{staticClass:"agree-checkbox"},[_c('van-checkbox',{attrs:{"icon-size":14},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("我已阅读并同意云算房")]),_c('span',{staticClass:"xy",on:{"click":function($event){_vm.agreementShow = true;
          _vm.xyIndex = 1;}}},[_vm._v("《服务协议》")]),_vm._v("及"),_c('span',{staticClass:"xy",on:{"click":function($event){_vm.agreementShow = true;
          _vm.xyIndex = 2;}}},[_vm._v("《隐私保护协议》")])],1),_c('div',{staticClass:"login-form-login",on:{"click":_vm.onClickLogin}}),_c('van-dialog',{attrs:{"title":"云算房服务协议及隐私保护","show-cancel-button":false,"show-confirm-button":false},model:{value:(_vm.agreeConfrim),callback:function ($$v) {_vm.agreeConfrim=$$v},expression:"agreeConfrim"}},[_c('div',{staticClass:"agree-tip"},[_vm._v(" 为了更好的保障您的合法权益，请阅读并同意以下协议 "),_c('span',{staticClass:"xy",on:{"click":function($event){_vm.agreementShow = true;
            _vm.xyIndex = 1;}}},[_vm._v("《云算房服务协议》")]),_vm._v(" 及 "),_c('span',{staticClass:"xy",on:{"click":function($event){_vm.agreementShow = true;
            _vm.xyIndex = 2;}}},[_vm._v("《云算房隐私保护协议》")]),_vm._v("，同意后 验证码将发送到您的手机。 ")]),_c('div',{staticClass:"agree-btn"},[_c('van-button',{attrs:{"type":"default"},on:{"click":function($event){_vm.agreeConfrim = false}}},[_vm._v("不同意")]),_c('van-button',{attrs:{"type":"info"},on:{"click":function($event){_vm.agreeConfrim = false;
            _vm.checked = true;}}},[_vm._v("同意")])],1)]),_c('div',{staticClass:"rightRuleTag",on:{"click":function($event){_vm.ruleShow = true}}},[_vm._m(0)]),_c('van-popup',{style:({ width: '80%' }),attrs:{"closeable":"","round":""},model:{value:(_vm.ruleShow),callback:function ($$v) {_vm.ruleShow=$$v},expression:"ruleShow"}},[_c('ActivityRule')],1),_c('van-popup',{style:({ height: '100%' }),attrs:{"closeable":"","position":"bottom"},model:{value:(_vm.agreementShow),callback:function ($$v) {_vm.agreementShow=$$v},expression:"agreementShow"}},[_c('div',{staticClass:"agreement"},[_c('div',{staticClass:"agreement-title"},[_vm._v(" "+_vm._s(_vm.xyIndex == 1 ? "云算房服务协议" : "云算房隐私保护协议")+" ")]),_c('div',{staticClass:"agreement-content"},[(_vm.xyIndex == 1)?_c('iframe',{staticStyle:{"width":"100%"},style:({ height: _vm.screenHeight + 'px' }),attrs:{"src":"/yhxy.html","frameborder":"0"}}):_vm._e(),(_vm.xyIndex == 2)?_c('iframe',{staticStyle:{"width":"100%"},style:({ height: _vm.screenHeight + 'px' }),attrs:{"src":"/ysxy.html","frameborder":"0"}}):_vm._e()])])])],1),(_vm.loading)?_c('loading',{attrs:{"text":_vm.text}}):_vm._e(),(!_vm.completeService && this.isWeXin)?_c('div',[_c('CompleteService',{on:{"click":_vm.onClickCompleteService}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rightRuleTagContent"},[_vm._v("查"),_c('br'),_vm._v("看"),_c('br'),_vm._v("活"),_c('br'),_vm._v("动"),_c('br'),_vm._v("规"),_c('br'),_vm._v("则")])
}]

export { render, staticRenderFns }